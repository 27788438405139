export default {
  burgerMenu: function() {
    (function (window, $) {

      $('.header__burger').on('click', function(){
        $(this).toggleClass('open');
        $('.internal-menu').toggleClass('open-internal-menu');
      });

      var rellax = new Rellax('.rellax');

      $('.collapse').collapse();


      /**
       * Scroll Anchor Animation
       */

      var headersHeight = $('header').height();

      $('a[href^="#"].roll').on('click',function (e) {
        e.preventDefault();
        var target = this.hash,
            $target = $(target);
        $('html, body').stop().animate({
          'scrollTop': $target.offset().top - headersHeight - 30
        }, 800);
      });


    })(window, jQuery);
  }
}
