export default {
  pageLoadedChecker: function () {
    const html = document.querySelector('html');

    function isCoarse() {
      const match = matchMedia('(pointer:coarse)').matches;
      if (match) {
        html.classList.add('coarse');
      }
    }

    let everythingLoaded = setInterval(function () {
      if (/loaded|complete/.test(document.readyState)) {
        clearInterval(everythingLoaded);

        html.classList.add('page-loaded');

        isCoarse();
      }
    }, 10);
  }


}
