import Swiper from 'swiper';

export default {
    sliderApt: function() {
        (function (window, $) {

            var swiper = new Swiper('.swiper-container', {
                spaceBetween: 5,
                grabCursor: true,
                loop: true,
                speed: 1000,
                autoplay: {
                    delay: 4000,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });

            lightGallery(document.getElementById('aniimated-thumbnials'), {
                thumbnail:true
            });


        })(window, jQuery);
    }
}