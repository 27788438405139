'use strict'

import global from './_global';
import lazyLoad from './_lazy-load';
import header from './_header';
import slider from './_sliders';

document.addEventListener("DOMContentLoaded", function () {
    global.pageLoadedChecker();
    lazyLoad.lazyLoad();
    header.burgerMenu();
    slider.sliderApt();
});
